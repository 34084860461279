<template>
  <div class="list">
    <div class="globle_border" v-loading="loading">
      <div class="search">
        <el-select v-model="purchaseStatus" placeholder="接单状态" clearable @change="onSearch">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-select v-model="signStatus" placeholder="签收状态" clearable @change="onSearch" style="margin-left: 20px">
          <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-select v-model="shippingStatus" placeholder="发货状态" clearable @change="onSearch" style="margin-left: 20px">
          <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-input v-model="supplierName" placeholder="请搜索供应商名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-input v-model="purchaseNum" placeholder="请搜索进货单编号" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-date-picker v-model="value1" @change="aa" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="border-radius: 10px; background: #f2f2f2; margin-left: 20px; border: none" value-format="yyyy-MM-dd"></el-date-picker>
      </div>
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column prop="number" label="序号" width="80">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="120" prop="purchaseNum" label="进货单编号" show-overflow-tooltip></el-table-column>
          <el-table-column min-width="120" prop="supplierName" label="供应商名称" show-overflow-tooltip></el-table-column>
          <el-table-column prop="contactMan" label="联系人" show-overflow-tooltip></el-table-column>
          <el-table-column prop="contactTel" label="联系电话" show-overflow-tooltip></el-table-column>
          <el-table-column prop="createTime" label="下单时间" show-overflow-tooltip></el-table-column>
          <el-table-column prop="purchaseStatus" label="接单状态">
            <template slot-scope="scope">
              <span :class="scope.row.purchaseStatus == 0 ? 'red' : 'grey'">
                {{ scope.row.purchaseStatus == 0 ? "待接单" : scope.row.purchaseStatus == 1 ? "已接单" : "已取消" }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="shippingStatus" label="发货状态">
            <template slot-scope="scope">
              <span>
                {{ scope.row.shippingStatus == 0 ? "待发货" : scope.row.shippingStatus == 2 ? "已取消" : "已发货" }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="signStatus" label="签收状态">
            <template slot-scope="scope">
              <span>
                {{ scope.row.signStatus == 0 ? "待签收" : scope.row.signStatus == 2 ? "已取消" : "已签收" }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" align="center" fixed="right" width="260px">
            <template slot-scope="scope">
              <!-- <el-button class="btn" type="primary" plain size="small" @click="showDetail(scope.row)" v-if="scope.row.purchaseStatus != 1&&scope.row.shippingStatus !=1 && scope.row.signStatus !=0">查看</el-button> -->
              <el-button class="btn" type="primary" plain size="small" @click="showDetail(scope.row)">查看</el-button>
              <el-button type="" plain @click="toIM(scope.row)">在线联系</el-button>
              <el-button class="btn" type="danger" plain size="small" @click="handelDelet(scope.row)" v-if="scope.row.shippingStatus == 0 && scope.row.purchaseStatus != 2">取消订单</el-button>
              <el-button class="btn" type="primary" plain size="small" @click="showDetail(scope.row)" v-if="scope.row.purchaseStatus == 1 && scope.row.shippingStatus == 1 && scope.row.signStatus == 0">确认收货</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>

    <el-dialog title="新增" :visible.sync="dialogVisible" :before-close="onCancel">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="支付宝真实姓名" prop="name">
              <el-input v-model="ruleForm.name" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="支付宝账号" prop="number">
              <el-input v-model="ruleForm.number" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="submitLoding">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value1: [],
      dialogVisible: false,
      submitLoding: false,
      purchaseStatus: "", //接单状态
      shippingStatus: "", //发货状态
      signStatus: "", // 签收状态
      options: [
        {
          value: "0",
          label: "待接单",
        },
        {
          value: "1",
          label: "已接单",
        },
      ],
      options1: [
        {
          value: "0",
          label: "待发货",
        },
        {
          value: "1",
          label: "已发货",
        },
      ],
      options2: [
        {
          value: "0",
          label: "待签收",
        },
        {
          value: "1",
          label: "已签收",
        },
      ],
      ruleForm: {
        name: "",
        number: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入支付宝真实姓名", trigger: "submit" },
          {
            validator: function (rule, value, callback) {
              if (/^[\u4e00-\u9fa5]+$/.test(value) == false) {
                callback(new Error("请输入支付宝真实姓名"))
              } else {
                //校验通过
                callback()
              }
            },
            trigger: "blur",
          },
        ],
        number: [
          { required: true, message: "请输入支付宝账号", trigger: "submit" },
          {
            validator: function (rule, value, callback) {
              if (/^\w{3,20}$/.test(value) == false) {
                callback(new Error("请输入邮箱或支付宝账号或手机号"))
              } else {
                //校验通过
                callback()
              }
            },
            trigger: "blur",
          },
        ],
      },
      loading: false,
      supplierName: "",
      purchaseNum: "",
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      value: "",
      shopId: "",
    }
  },
  created() {
    this.shopId = this.$store.state.userInfo.shopId
    // 缓存页面的搜索条件
    let obj = JSON.parse(localStorage.getItem("productList"))
    this.purchaseStatus = obj?.purchaseStatus
    this.signStatus = obj?.signStatus
    this.shippingStatus = obj?.shippingStatus
    this.supplierName = obj?.supplierName
    this.currentPage = obj?.currentPage
    this.purchaseNum = obj?.purchaseNum
    this.value1 = obj?.value1
    this.$saveOrUpdateCache("productList", {})
    this.getPurchase_cart()
  },
  methods: {
    // 商超列表
    getPurchase_cart() {
      this.loading = true
      // 时间区间
      let params = {
        shopId: this.shopId,
        supplierName: this.supplierName,
        purchaseNum: this.purchaseNum,
        purchaseStatus: this.purchaseStatus,
        signStatus: this.signStatus,
        shippingStatus: this.shippingStatus,
        page: this.currentPage,
        pageSize: this.pageSize,
        endTime: null,
        startTime: null,
      }

      const [startTime, endTime] = this.value1 ?? []
      params.startTime = startTime ?? null
      params.endTime = endTime ?? null

      this.$axios
        .get(this.$api.purchase_cart, { params })
        .then(res => {
          this.tableData = res.data.result.list
          this.totalItemsCount = res.data.result.totalCount
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 删除
    handelDelet(row) {
      this.$confirm("确定删除该订单吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        center: true,
        type: "warning",
      }).then(() => {
        this.$axios
          .post(this.$api.purchase_cart_del, {
            purchaseId: row.purchaseId,
          })
          .then(res => {
            if (res.data.code == 100) {
              this.getPurchase_cart()
              this.$message({
                message: res.data.desc,
                type: "success",
              })
            }
          })
      })
    },

    // 搜索
    aa(value) {
      this.tableData = []
      this.currentPage = 1
      this.value1 = value
      this.getPurchase_cart()
    },
    onSearch() {
      this.tableData = []
      this.currentPage = 1
      this.getPurchase_cart()
    },

    // 切换每页显示的条数
    handleSizeChange(e) {
      this.tableData = []
      this.pageSize = e
      this.getPurchase_cart()
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e
      this.getPurchase_cart()
    },

    // 提现
    onWithdrawal() {
      this.dialogVisible = true
    },
    // 查看
    showDetail(row) {
      let obj = {
        purchaseStatus: this.purchaseStatus,
        signStatus: this.signStatus,
        shippingStatus: this.shippingStatus,
        supplierName: this.supplierName,
        currentPage: this.currentPage,
        purchaseNum: this.purchaseNum,
        value1: this.value1,
      }

      // 缓存页面的搜索条件
      this.$saveOrUpdateCache("productList", obj)
      this.$router.push({
        path: "/procure/detail/info",
        query: {
          purchaseId: row.purchaseId,
        },
      })
    },
    toIM(row) {
      // 判断当前环境
      if (process.env.NODE_ENV === "production") {
        window.open(`https://consult.xiaoyuanshenghuowang.com/#/b2b?ssid=${this.$store.state.userInfo.shopId}&sid=${row.supplierId}&type=${2}`) //type类型：1供应商；2商超；3骑手；4商城；5总后台
      } else {
        window.open(`http://192.168.2.6:8080/#/b2b?ssid=${this.$store.state.userInfo.shopId}&sid=${row.supplierId}&type=${2}`) //type类型：1供应商；2商超；3骑手；4商城；5总后台
      }
    },
    // 关闭对话框
    onCancel() {
      // this.resetForm()
      this.dialogVisible = false
    },
    // 重置表单
    resetForm() {
      this.ruleForm = {
        number: this.ruleForm.number,
        name: this.ruleForm.name,
      }
      this.$refs.ruleForm.resetFields()
    },
    // 提交表单
    // submitForm(formName) {
    //   let param = {
    //     contractType: this.ruleForm.contractType,
    //     templateName: this.ruleForm.templateName,
    //     fileId: this.fileId,
    //     fileUrl: this.fileUrl,
    //   }
    //   this.submitLoding = true
    //   this.$refs[formName].validate((valid) => {
    //     if (valid) {
    //       this.$axios.post(this.$api.uploadTemplate, param).then((res) => {
    //         if (res.data.code == 100) {
    //           this.$message.success('添加成功');
    //         }
    //         this.getPurchase_cart();
    //         this.submitLoding = false
    //         this.dialogVisible = false;
    //         this.resetForm();
    //       });
    //     } else {
    //       this.submitLoding = false
    //       this.$message.error('提交失败');
    //       return false;
    //     }
    //   });
    // },
  },
}
</script>

<style scoped lang="scss">
.list {
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    // height: 44px;

    .el-range-editor {
      width: 300px;
      margin-bottom: 20px;
    }

    .el-select,
    .el-input {
      margin-left: 20px;
      margin-bottom: 20px;
      width: 160px;
    }

    .el-button {
      margin-left: 20px;
      margin-bottom: 20px;
    }
  }

  .show_img {
    color: #069bbc;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  .red {
    color: red;
  }

  .grey {
    color: grey;
  }
}
</style>
